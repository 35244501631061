<template>
  <div class="pdf_page box">
    <div class="page_left">
      <div class="icon_list">
        <div @click="iconClick(item.id)" class="icon_item" v-for="item in iconList" :key="item.id">
          <div :class="'iconfont color1 ' + item.icon + (tabActiveId == item.id ? ' tabActiveIcon' : '')"></div>
          <span :class="tabActiveId == item.id ? 'tabActiveText' : ''">{{ item.name }}</span>
        </div>
      </div>
      <div class="time_list">
        <div @click="timeListClick(index)" :class="index == timeActiveIndex ? 'avtive' : ''" v-for="(item, index) in timeList" :key="item.id">
          {{ item.name }}
          <span v-show="index == timeActiveIndex"></span>
        </div>
      </div>
      <div class="final_list">
        <div v-for="(item, index) in reportData" :key="index" :class="index == finalActiveIndex ? 'active' : ''" @click="finalClick(item, index)">
          {{ item.textTitle }}
        </div>
      </div>
    </div>
    <div ref="onePageContent" class="page_right" v-if="pdfShowMode == 1 && src">
      <!-- <iframe style="width: 100%; height: 100%" src="http://114.115.162.96:9000/bhms-2.0/20230501/files/777b399d-9245-4106-a5df-5249a1b6313b.pdf?X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Credential=admin%2F20230510%2Fus-east-1%2Fs3%2Faws4_request&X-Amz-Date=20230510T072113Z&X-Amz-Expires=604800&X-Amz-SignedHeaders=host&X-Amz-Signature=62dd511ec6e910aae4c84587d96f0223677599e929efb71dc92b02f747662024" frameborder="0"></iframe> -->
      <pdf ref="pdf" @loaded="loadPdfHandler" class="pdf1" v-for="i in currPages" :key="i" :src="src" :page="i"> </pdf>
      <div ref="targetElement1" class="pageLoadTxte" style="color: #9fd5ff; text-align: center; margin-bottom: 20px">
        {{ loadOver ? "没有更多数据了" : "正在加载中..." }}
      </div>
    </div>
    <div ref="twoPageContent" class="page-right-two" v-if="pdfShowMode == 2 && src">
      <div style="display: flex; flex-direction: row">
        <div class="page-col">
          <pdf ref="pdf" @loaded="loadPdfHandler" class="pdf1" v-for="i in currOddArray" :key="i" :src="src" :page="i"> </pdf>
        </div>
        <div class="page-col">
          <pdf ref="pdf" @loaded="loadPdfHandler" class="pdf1" v-for="i in currEvenArray" :key="i" :src="src" :page="i"> </pdf>
        </div>
      </div>
      <div ref="targetElement2" class="pageLoadTxte" style="color: #9fd5ff; text-align: center; margin-bottom: 20px">
        {{ loadOver ? "没有更多数据了" : "正在加载中..." }}
      </div>
    </div>
  </div>
</template>

<script>
import pdf from "vue-pdf";
import moment from "moment";
export default {
  components: {
    pdf,
  },
  data() {
    return {
      tabActiveId: 1,
      pdfShowMode: 1,
      iconList: [
        { icon: "icon-shanli", name: "单列", id: 1 },
        { icon: "icon-shuangli", name: "双列", id: 2 },
        { icon: "icon-xiazai", name: "下载", id: 3 },
        { icon: "icon-dayin", name: "打印", id: 4 },
      ],
      timeList: [
        { name: "月度", id: 1 },
        // { name: "季度", id: 2 },
        // { name: "年度", id: 3 },
      ],
      timeActiveIndex: 0,
      projectId: "",
      currentPage: 1,
      reportData: [],
      finalActiveIndex: 0,
      currPages: 0,
      numPages: 10,
      totalPages: 0,
      src: "", //pdf 报告的id
      srcId: "",
      iframesrc: "",
      oddArray: [],
      evenArray: [],
      currOddArray: [],
      currEvenArray: [],
      isObserve1:false,
      isObserve2:false,

      loadOver: false,
    };
  },
  mounted() {
    this.projectId = sessionStorage.getItem("projectId");
    this.finalPage();
    this.observer = new IntersectionObserver(this.handleIntersection, {
      root: null,
      rootMargin: "0px",
      threshold: 0.5,
    });

  
  },
  beforeDestroy() {
    if (this.observer) {
      this.observer.disconnect();
    }
  },
  methods: {
    handleIntersection(entries) {
      entries.forEach((entry) => {
        console.log("dasdsada");

        if (entry.isIntersecting) {
          console.log("元素可见");
          this.loadMore();
        } else {
        }
      });
    },
    handleScroll() {
      const currentZoomLevel = Math.round(window.devicePixelRatio * 100) / 100;

      var scrollContainer;
      if (this.pdfShowMode == 1) {
        scrollContainer = this.$refs.onePageContent;
      } else {
        scrollContainer = this.$refs.twoPageContent;
      }
      if (scrollContainer.scrollTop + scrollContainer.clientHeight >= scrollContainer.scrollHeight) {
        this.loadMore();
      }
    },

    loadMore() {
      if (this.pdfShowMode == 1) {
        if (this.loadOver) {
          return;
        }
        if (this.totalPages - this.currPages > 0) {
          if (this.totalPages - this.currPages > this.numPages) {
            this.currPages += this.numPages;
          } else {
            this.currPages += this.totalPages - this.currPages;
            this.loadOver = true;
          }
        } else {
          this.loadOver = true;
        }
      }
      if (this.pdfShowMode == 2) {
        if (this.loadOver) {
          return;
        }

        if (this.oddArray.length - this.currOddArray.length > 0) {
          if (this.oddArray.length - this.currOddArray.length > this.numPages) {
            this.currOddArray = this.currOddArray.concat(this.oddArray.slice(this.currOddArray.length, this.currOddArray.length + this.numPages));
          } else {
            this.currOddArray = this.currOddArray.concat(this.oddArray.slice(this.currOddArray.length, this.currOddArray.length + (this.oddArray.length - this.currOddArray.length)));
            this.loadOver = true;
          }
        } else {
          this.loadOver = true;
        }

        if (this.evenArray.length - this.currEvenArray.length > 0) {
          if (this.evenArray.length - this.currEvenArray.length > this.numPages) {
            this.currEvenArray = this.currEvenArray.concat(this.evenArray.slice(this.currEvenArray.length, this.currEvenArray.length + this.numPages));
          } else {
            this.currEvenArray = this.currEvenArray.concat(this.evenArray.slice(this.currEvenArray.length, this.currEvenArray.length + (this.evenArray.length - this.currEvenArray.length)));
          }
        }
      }
      // this.numPages = this.numPages + 3;
    },
    timeListClick(index) {
      this.timeActiveIndex = index;
      this.finalPage();
    },
    iconClick(id) {
      if (id == 1) {
        this.tabActiveId = id;
        this.pdfShowMode = 1;
        this.pdfSite();
      }
      if (id == 2) {
        this.tabActiveId = id;
        this.pdfShowMode = 2;
        this.pdfSite();
      }
      if (id == 3) {
        this.pdfPrintAll();
      }
      if (id == 4) {
        this.printCurrentPDF();
      }
    },
    // 添加一个新的方法用于打印当前展示的 PDF
    printCurrentPDF() {
      // 获取当前正在展示的 PDF 的实例
      const currentPdfInstance = this.$refs.pdf[0];
      // 判断是否存在 PDF 实例
      if (currentPdfInstance && currentPdfInstance.print) {
        // 调用 PDF 实例的打印方法
        currentPdfInstance.print();
      } else {
        console.error("无法获取 PDF 实例或 PDF 实例不支持打印功能");
      }
    },
    finalPage() {
      this.$axios.get(`${this.baseURL}report/final/page?current=${this.currentPage}&size=20&projectId=${this.projectId}&type=${this.timeActiveIndex + 2}`).then((res) => {
        let { records, total } = res.data.data;
        if (records && records.length) {
          records.map((item) => {
            item.textTitle = moment(item.startTime).year() + "年" + (moment(item.startTime).month() + 1) + "月监测报告";
          });
          this.srcId = records[0].id;
          this.reportData = records;
          this.total = total;
          this.pdfSite();
        } else {
          this.src = "";
          this.reportData = records;
          this.total = total;
        }
      });
    },
    finalClick(item, index) {
      this.finalActiveIndex = index;
      this.srcId = item.id;
      this.pdfSite();
    },
    loadPdfHandler() {},
    pdfPrintAll() {
      if (!this.myOption) {
        this.$message.error("您没有权限！");
        return;
      }
      this.downLoad(this.reportData[this.finalActiveIndex].textTitle + ".pdf");

      // var a = document.createElement("a");
      // a.href = this.iframesrc;
      // a.download = this.timeList[this.timeActiveIndex].name
      // console.log(a);
      // const agent = window.navigator;
      // if (
      //   agent.userAgent.match(/QQ/i) ||
      //   (agent.mimeTypes[0] && agent.mimeTypes[0].type.match(/360/i))
      // ) {
      //   // a.target = '_blank'
      // }
      // a.click();
    },
    downLoad(name) {
      const x = new window.XMLHttpRequest();
      x.open("GET", this.iframesrc, true);
      x.responseType = "blob";
      x.onload = () => {
        const url = window.URL.createObjectURL(x.response);
        const a = document.createElement("a");
        a.href = url;
        a.target = "_blank";
        a.download = name;
        a.style.display = "none";
        document.body.append(a);
        a.click();
      };
      x.send();
    },
    // 获取 pdf 地址
    pdfSite() {
      this.src = "";
      this.$axios.get(`${this.baseURL}report/final/download/${this.srcId}`).then((res) => {
        this.totalPages = 0;
        this.currPages = 1;
        this.loadOver = false;
        this.currEvenArray = [];
        this.currOddArray = [];

        var src = res.data.data;
        this.iframesrc = src;

        // 展示pdf
        this.pdfTask(src);
      });
    },
    // pdf展示
    pdfTask(src) {
      this.winBottom = 2;
      this.loadingPage = this.$loading({
        lock: true,
        text: "正在加载...",
        spinner: "el-icon-loading",
        background: "rgba(0, 0, 0, 0.7)",
      });
      this.src = pdf.createLoadingTask(src);
      this.src.promise.then((pdf) => {
        var totalPages = pdf.numPages;
        this.totalPages = totalPages;
        if (this.pdfShowMode == 1) {
          this.observer.observe(this.$refs.targetElement1);
          if (totalPages > this.numPages) {
            this.currPages = this.numPages;
            this.loadOver = false;
          } else {
            this.currPages = totalPages;
            this.loadOver = true;
          }
          // this.numPages = numPages;
        }
        if (this.pdfShowMode == 2) {
          let oddArray = [];
          let evenArray = [];
          this.observer.observe(this.$refs.targetElement2);

          for (let i = 1; i <= totalPages; i++) {
            if (i % 2 === 1) {
              oddArray.push(i);
            } else {
              evenArray.push(i);
            }
          }
          this.oddArray = oddArray;
          this.evenArray = evenArray;
          if (this.oddArray.length > this.numPages) {
            this.currOddArray = this.currOddArray.concat(this.oddArray.slice(0, this.numPages));
          } else {
            this.currOddArray = this.oddArray;
            this.loadOver = true;
          }
          if (this.evenArray.length > this.numPages) {
            this.currEvenArray = this.currEvenArray.concat(this.evenArray.slice(0, this.numPages));
          } else {
            this.currEvenArray = this.evenArray;
          }
        }
        setTimeout(() => {
          this.loadingPage.close();
        }, 500);
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.tabActiveIcon {
  color: #32c5ff !important;
}
.tabActiveText {
  color: #32c5ff !important;
}
.pdf_page {
  height: calc(100% - 150px) !important;
  margin-top: 20px;
  .page_left {
    height: calc(100%);
    min-width: 300px;
    background: #131f4673;
    border: 1px solid #0c3380;
    margin-right: 16px;
  }
  .page_right {
    height: calc(100%);
    flex: 1;
    border: 1px solid #0c3380;
    overflow-y: auto;
    overflow-x: hidden;
    padding: 0 20%;
    .pdf1 {
      margin-bottom: 20px;
    }
  }
  .page-right-two {
    display: flex;
    flex-direction: column;
    border: 1px solid #0c3380;
    overflow-y: auto;
    overflow-x: hidden;
    padding: 0 10%;
    width: 100%;
    .page-col {
      width: 100%;
      margin-right: 20px;
      .pdf1 {
        margin-bottom: 20px;
      }
    }
  }
  .icon_list {
    display: flex;
    font-size: 14px;
    height: 40px;
    border-bottom: 1px solid #0c3380;
    padding: 0 20px;
    .icon_item {
      flex: 1;
      display: flex;
      align-items: center;
      cursor: pointer;
      &:hover,
      &:active {
        .iconfont {
          font-size: 14px;
          color: #32c5ff;
        }
        span {
          color: #32c5ff;
        }
      }
      .iconfont {
        font-size: 14px;
        color: #9fd5ff;
      }
      span {
        color: #9fd5ff;
      }
    }
  }
  .time_list {
    height: 44px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid #0c3380;
    .avtive {
      color: #ffffff;
    }
    div {
      flex: 1;
      text-align: center;
      color: #ccdaff;
      border-left: 1px solid #3a4a7b;
      position: relative;
      font-size: 14px;
      cursor: pointer;
      span {
        position: absolute;
        background-image: linear-gradient(90deg, #00135a00 0%, #1e87ff 54%, #00135b00 100%);
        background-repeat: no-repeat;
        width: 56px;
        height: 4px;
        left: 50%;
        bottom: -10px;
        transform: translate(-50%);
      }
    }
  }
  .final_list {
    height: calc(100% - 90px);
    overflow-y: auto;
    div {
      line-height: 36px;
      font-size: 14px;
      color: #ccdaff;
      padding-left: 31px;
      cursor: pointer;
      margin-top: 5px;
      font-weight: 500;
    }
    .active {
      background-image: linear-gradient(270deg, #2c4a97 0%, #1c2f63 100%);
      color: #fff;
    }
  }
}
</style>